
import { Table, TableData, TableHeader, TableRow } from '@/components/table'
// import { OfferReadDto } from 'tradingmate-modules/src/models/api/offers'
import { Services } from 'tradingmate-modules/src/services'
import Utils from 'tradingmate-modules/src/Utils'
import { Component, Vue } from 'vue-property-decorator'
import { PagedResultSet } from '../../../../../tradingmate-modules/src/models/api/common'
import Paging from '@/components/actions/Paging.vue'
import Badge from '@/components/status/Badge.vue'
import { BlogModel } from '../../../../../tradingmate-modules/src/models/api/blogs'
import BlogFilter from '../../../../../tradingmate-modules/src/filters/BlogFilter'
import BlogType from '../../../../../tradingmate-modules/src/models/api/blogs/BlogType'

@Component({
  components: {
    Table,
    TableRow,
    TableData,
    TableHeader,
    Badge,
    Paging
  }
})

export default class AdminContentOffers extends Vue {
  private offers: PagedResultSet<BlogModel> | null = null

  private loading = false

  private isoToDisplayDate = Utils.isoToDisplayDate

  mounted (): void {
    this.getOffers()
  }

  private filter = new BlogFilter({ Page: 1, BlogType: BlogType.Offer })
  getOffers (): void {
    this.loading = true
    Services.API.Blogs.GetAllBlogs(this.filter)
      .then((res) => {
        this.offers = res
      }).finally(() => {
        this.loading = false
      })
  }

  onEdit (slug: string): void {
    this.$router.push({ name: 'Edit Offer', params: { slug: slug } })
  }

  private isMarkingSeen = ''
  onMarkedSeen (offer: BlogModel): void {
    this.isMarkingSeen = offer.BlogId
    Services.API.Admin.Content.MarkSeenBlog(offer.BlogId)
      .then((result) => {
        if (!this.offers) return
        offer.MarkedSeen = true
      })
      .finally(() => {
        this.loading = false
        this.isMarkingSeen = ''
      })
  }

  onPaged (page: number): void {
    this.filter.Page = page
    this.getOffers()
  }
}

